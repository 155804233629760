<template>
<!-- 体育协会 全市协会照片风采一览 -->
	<div>
		<div class="container darkBack">
			<div class="font22 whiteFont">全市协会照片风采一览</div>
			<!-- 照片组 -->
			<div class="photo">
				<img :src="item" v-for="(item,index) in image.img" :key="index">
			</div>
		</div>
	</div>
</template>

<script>
    import {getAjax, timestamp} from "../../../assets/js/websocket";
	export default {
		data() {
			return{
				image:{
					img:[
						// require('../../../assets/images/01.png'),
						// require('../../../assets/images/02.png'),
						// require('../../../assets/images/03.png'),
						// require('../../../assets/images/04.png'),
						// require('../../../assets/images/05.png'),
						// require('../../../assets/images/06.png'),
						// require('../../../assets/images/07.png'),
						// require('../../../assets/images/08.png'),
						// require('../../../assets/images/09.png'),
						// require('../../../assets/images/10.png'),
						// require('../../../assets/images/11.png'),
						// require('../../../assets/images/12.png'),
						// require('../../../assets/images/13.png'),
						// require('../../../assets/images/14.png'),
						// require('../../../assets/images/15.png'),
						// require('../../../assets/images/16.png'),
					]
				},
				
			}
		},
        created() {
            var that = this
            var data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            getAjax({
                url: "/map/screen/getSportsAssociationAll",
                method:"GET",
                data:data,
                success:function(res){
					// console.log(res)
                    if(res.result){
                        // console.log(55555555555, res.data.list)
                        // that.image.img = res.data.list[0].image_arr
						that.image.img = [];
						res.data.list.forEach(item=>{
							if(item.image_arr.length > 0) that.image.img.push(item.image_arr[0])
						})
                    }
                },
            })
        },
		methods:{
			
		},
		mounted() {
			
		}
	}
</script>

<style scoped>
	.container{
		width: 12.11rem;
		height: 6.3rem;
		padding: 0.2rem;
		position: relative;
		overflow: hidden;
	}
	.photo{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
        flex-wrap:wrap;
		margin-top: 0.2rem;
		height: 5rem;
		overflow-y: scroll;
	}
	 /*滚动条样式*/
	.photo::-webkit-scrollbar {
		width: 0.05rem;    
		height: 0rem;
	}


	.photo::-webkit-scrollbar-thumb {
		border-radius: 0.1rem;
		box-shadow: inset 0 0 0.05rem #bec5ce;
		background: #7EA7E2;
	}
	.photo::-webkit-scrollbar-track {
		box-shadow: inset 0 0 0.05rem #7EA7E2;
		border-radius: 0.1rem;
	}
	.photo img{
		width: 2.61rem;
		height: 1.34rem;
		margin-top: 0.2rem;
        margin-right:0.3rem;
	}
</style>